<template>
  <div class="container">
    <a-layout>
      <Header />
      <a-layout>
        <Sider />
        <a-layout class="section">
          <a-layout-content
            :style="{
              margin: '24px 16px',
              padding: '24px',
              marginTop: '24px',
              background: '#fff',
              minHeight: '280px',
            }"
          >
            <div class="container">
              <div ref="box">
                <div ref="header">
                  <a-page-header
                    style="border-bottom: 1px solid rgb(235, 237, 240)"
                    title="提现管理"
                  >
                    <!-- <template slot="extra">
                      <a-button
                        class="editable-add-btn"
                        @click="handleAdd"
                        size="small"
                      >
                        新增
                      </a-button>
                    </template> -->
                  </a-page-header>
                </div>
                <div ref="form">
                  <a-form
                    :form="form"
                    layout="inline"
                    @submit="handleSubmit"
                    style="padding: 15px 0"
                  >
                    <a-form-item label="用户ID">
                      <a-input
                        v-decorator="[
                          'users_id',
                          { rules: [{ required: false }] },
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="用户手机号">
                      <a-input
                        v-decorator="[
                          'users_mobile',
                          { rules: [{ required: false }] },
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="转账支付宝号码">
                      <a-input
                        v-decorator="[
                          'zfb_pay_mobile',
                          { rules: [{ required: false }] },
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="提现状态">
                      <a-select
                        placeholder="全部"
                        style="width: 180px"
                        v-decorator="[
                          'status',
                          { rules: [{ required: false }] },
                        ]"
                      >
                        <a-select-option value=""> 全部 </a-select-option>
                        <a-select-option value="1"> 待审核 </a-select-option>
                        <a-select-option value="2"> 成功 </a-select-option>
                        <a-select-option value="3"> 失败 </a-select-option>
                        <a-select-option value="4"> 进行中 </a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item>
                      <a-button
                        type="primary"
                        html-type="submit"
                        @click="getExport(0)"
                      >
                        搜索
                      </a-button>
                    </a-form-item>
                  </a-form>
                </div>
                <a-table
                  :columns="columns"
                  :data-source="withdrawListist"
                  :pagination="false"
                  :scroll="{ x: 1000, y: table_h }"
                  :row-key="(record) => record.withdrawal_record_id"
                  size="middle"
                  ref="tableList"
                >
                  <div
                    slot="zfb_pay_name"
                    slot-scope="zfb_pay_name, num"
                    style="text-align: left"
                  >
                    <span>姓名：{{ zfb_pay_name ? zfb_pay_name : "--" }}</span
                    ><br />
                    <span>账号：{{ num.zfb_pay_mobile }}</span
                    ><br />
                  </div>
                  <div slot="status" slot-scope="status, num">
                    <div v-if="status == 1">待审核</div>
                    <div v-if="status == 2">成功</div>
                    <div v-if="status == 3">
                      失败<br />（原因：{{ num.reason }}）
                    </div>
                    <div v-if="status == 4">
                      进行中
                    </div>
                  </div>
                  <span slot="btns" slot-scope="btns, num">
                    <a-button
                      class="editable-add-btn"
                      v-for="btn in btns"
                      :key="btn"
                      @click="handel(btn, num)"
                      size="small"
                      style="margin: 5px"
                    >
                      {{ btn }}
                    </a-button>
                  </span>
                </a-table>
                <div ref="pagination">
                  <a-pagination
                    v-if="pageshow"
                    :default-current="currentPage"
                    :defaultPageSize="defaultPageSize"
                    :page-size-options="pageSizeOptions"
                    show-size-changer
                    show-quick-jumper
                    :total="total_num"
                    @showSizeChange="onShowSizeChange"
                    @change="onPageChange"
                    :item-render="itemRender"
                  >
                    <template slot="buildOptionText" slot-scope="props">
                      <span>{{ props.value }}条/页</span>
                    </template>
                  </a-pagination>
                </div>
                <a-modal
                  v-model="visible"
                  :title="modelTitle"
                  :confirm-loading="confirmLoading"
                  @ok="handleOk"
                  ok-text="确认"
                  cancel-text="取消"
                >
                  <a-form :form="addform" @submit="handleSubmit">
                    <a-form-item label="拒绝原因：" v-if="status==3">
                      <a-input
                        v-decorator="[
                          'reason',
                          {
                            rules: [
                              { required: true, message: '请输入拒绝原因!' },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="备注：">
                      <a-input
                        v-decorator="[
                          'remarks',
                          {
                            rules: [
                              { required: false, message: '请输入备注!' },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </a-form>
                </a-modal>
              </div>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Sider from "@/components/Sider.vue";
export default {
  name: "Car",
  data() {
    return {
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      addform: this.$form.createForm(this, { name: "addform" }),
      export: 0,
      columns: [
        {
          title: "用户昵称",
          key: "nickname",
          dataIndex: "nickname",
          align: "center",
        },
        {
          title: "用户注册手机号",
          key: "mobile",
          dataIndex: "mobile",
          align: "center",
        },
        {
          title: "支付宝",
          key: "zfb_pay_name",
          dataIndex: "zfb_pay_name",
          scopedSlots: { customRender: "zfb_pay_name" },
          align: "center",
          width: "150px",
        },
        {
          title: "提现金额（元）",
          key: "amount",
          dataIndex: "amount",
          align: "center",
        },
        {
          title: "提现时间",
          key: "withdrawal_time",
          dataIndex: "withdrawal_time",
          align: "center",
        },
        {
          title: "状态",
          key: "status",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
        },
        {
          title: "备注",
          key: "remarks",
          dataIndex: "remarks",
          align: "center",
        },

        {
          title: "操作",
          key: "btns",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
        },
      ], 
      visible: false,
      modelTitle: "",
      confirmLoading: false,
      pageshow: false,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      table_h: "",
      withdrawListist: [],
      status:""
    };
  },
  components: {
    Header,
    Sider,
  },
  mounted() {
    window.addEventListener("resize", () => this.changeTableH(), true);
    this.getSearchList({});
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.header.offsetHeight -
        this.$refs.form.offsetHeight -
        this.$refs.pagination.offsetHeight -
        60;
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      var that = this;
      this.defaultPageSize = pageSize;
      if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
        that.currentPage = 1;
      }
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取列表
    getSearchList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      data.startTime = that.startTime;
      data.endTime = that.endTime;
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/wallet/withdrawalRecordList", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var withdrawListist = res.data.data.list;
            for (var i = 0; i < withdrawListist.length; i++) {
              if (withdrawListist[i].status == 1) {
                withdrawListist[i].btns = ["通过并转账","通过", "拒绝"];
              }
            }
            that.changeTableH();
            that.withdrawListist = withdrawListist;
            that.total_num = res.data.data.hsaMore
              ? res.data.data.page * data.limit + 1
              : res.data.data.page * data.limit;
            that.currentPage = res.data.data.page;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    // 操作
    handel(btn, num) {
      var that = this;
      that.withdrawal_record_id = num.withdrawal_record_id;
      if (btn == "通过并转账") {
        that.$confirm({
          title: "提示",
          content: "是否通过并转账",
          okText: "确认",
          cancelText: "取消",
          onOk() {
            that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
            data = {
              withdrawal_record_id: that.withdrawal_record_id,
            };
            that.axios
              .get("/admin/wallet/review/agreeWithTransfer", { params: data })
              .then((res) => {
                that.$message.loading({
                  content: "加载中..",
                  key: "load",
                  duration: 0.00001,
                });
                if (that.$code(res)) {
                  that.$message.success(res.data.msg);
                  that.form.validateFields((error, values) => {
                  that.getSearchList(values);
                });
                }
              });
          },
        });
      }
      if (btn == "通过") {
        var data = {};
        data.withdrawal_record_id = num.withdrawal_record_id;
        that.status = 2;that.visible = true;
        that.modelTitle = "审核通过";

        that.confirmLoading = false;
        that.$nextTick(() => {
          that.addform.setFieldsValue({
            remarks: "",
          });
        });
      }
      if (btn == "拒绝") {
        that.status = 3;
        that.visible = true;
        that.modelTitle = "拒绝原因";

        that.confirmLoading = false;
        that.$nextTick(() => {
          that.addform.setFieldsValue({
            reason: "",
            remarks: "",
          });
        });
      }
    },
    //拒绝原因
    handleOk() {
      var that = this;
      that.confirmLoading = true;
      that.addform.validateFields((error, values) => {
        if (!error) {
          var data = values;
          data.withdrawal_record_id = that.withdrawal_record_id;
          data.status = that.status;
          that.axios
            .get("/admin/wallet/reviewWithdrawal", { params: data })
            .then((res) => {
              that.$message.loading({
                content: "加载中..",
                key: "load",
                duration: 0.00001,
              });
              if (that.$code(res)) {
                that.$message.success("已审核");
                that.form.validateFields((error, values) => {
                  that.getSearchList(values);
                });
              }
              that.visible = false;
              that.confirmLoading = false;
            });
        } else {
          that.confirmLoading = false;
        }
      });
      // this.visible = false;
    },
    //筛选 导出设备
    getExport(e) {
      this.export = e;
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        } else {
          var data = "";
          for (var key in values) {
            if (values[key]) {
              data += "&" + key + "=" + values[key];
            }
          }
          var myObj = {
            method: "get",
            url: "/" + that.pageNowUrl + "?export=1" + data,
            fileName: "设备列表.csv",
          };
          this.$exportMethod(myObj);
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 100px;
}
.ant-table-wrapper {
  flex: 1;
}
.goodsInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 250px;
}
.goodsInfo img {
  width: 80px;
  height: 80px;
}
.goodsInfo > div {
  width: 165px;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 80px;
}
.goodsInfo > div > div {
  width: 165px;
  text-align: left;
}
/* .goodsInfo>div>div>div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
} */
.goodsInfo > div > div span {
  color: #1890ff;
}
.goodsInfo p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
}
.orderTime {
  text-align: left;
}
/deep/.ant-pagination .ant-pagination-item {
  display: none;
}
</style>