<template>
  <div class="slider_box">
    <div
      :class="{ menu_app_bg: !menu_app && slider_app }"
      @click="menu_app = !menu_app"
    ></div>
    <a-layout-sider
      v-model="collapsed"
      :trigger="null"
      :class="{ slider_app: slider_app, slider_app_h: menu_app }"
      ref="sider"
    >
      <div
        class="logo"
        v-if="slider_app"
        @click="getMenu"
        style="background: #fff; color: #000"
      >
        <img src="../assets/logo.png" />
      </div>
      <a-menu
        theme="dark"
        :selected-keys="current"
        :open-keys="openKeys"
        mode="inline"
        @click="handleClick"
        @openChange="onOpenChange"
        :class="{ menu_app: menu_app }"
      >
        <a-sub-menu key="/order" @titleClick="titleClick">
          <span slot="title">
            <a-icon type="bars" />
            <span>订单管理</span>
          </span>
          <a-menu-item key="/list"> 订单列表 </a-menu-item>
          <a-menu-item key="/first"> 首购订单 </a-menu-item>
        </a-sub-menu>
        <a-menu-item key="/tbMarketing">
          <a-icon type="deployment-unit" />
          <span>淘宝营销</span>
        </a-menu-item>
        <a-menu-item key="/withdraw">
          <a-icon type="euro" />
          <span>提现管理</span>
        </a-menu-item>
        <a-sub-menu key="/activity"  @titleClick="titleClick">
          <span slot="title">
            <a-icon type="robot" />
            <span>活动管理</span>
          </span>
          <a-menu-item key="/list"> 活动列表 </a-menu-item>
          <a-menu-item key="/task"> 活动任务模板 </a-menu-item>
          <a-menu-item key="/task/list"> 活动任务管理 </a-menu-item>
        </a-sub-menu>
        <a-menu-item key="/poster">
          <a-icon type="picture" />
          <span>海报管理</span>
        </a-menu-item>
        <a-sub-menu key="/assignment"  @titleClick="titleClick">
          <span slot="title">
            <a-icon type="robot" />
            <span>任务管理</span>
          </span>
          <a-menu-item key="/command"> 任务参加管理 </a-menu-item>
          <a-menu-item key="/reward"> 奖励明细 </a-menu-item>
        </a-sub-menu>
        <a-menu-item key="/upgrade">
          <a-icon type="rise" />
          <span>付费升级</span>
        </a-menu-item>
        <a-menu-item key="/user">
          <a-icon type="team" />
          <span>用户管理</span>
        </a-menu-item>
        <a-menu-item key="/robot">
          <a-icon type="reddit" />
          <span>机器人管理</span>
        </a-menu-item>
        <!-- <a-menu-item key="/credentials">
          <a-icon type="appstore" />
          <span>认证信息</span>
        </a-menu-item> -->
        <a-sub-menu key="/credentials" @titleClick="titleClick">
          <span slot="title">
            <a-icon type="credit-card" />
            <span>审核管理</span>
          </span>
          <!-- <a-menu-item key="/list">认证列表 </a-menu-item> -->
          <a-menu-item key="/check"> 认证审核 </a-menu-item>
          <a-menu-item key="/h5"> H5活动审核 </a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="/firstReward" @titleClick="titleClick">
          <span slot="title">
            <a-icon type="transaction" />
            <span>首购奖励</span>
          </span>
          <!-- <a-menu-item key="/list">认证列表 </a-menu-item> -->
          <a-menu-item key="/goods"> 商品管理 </a-menu-item>
          <a-menu-item key="/empower"> 授权管理 </a-menu-item>
          <a-menu-item key="/users"> 用户信息 </a-menu-item>
          <a-menu-item key="/reward"> 发放佣金 </a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="/otherSet" @titleClick="titleClick">
          <span slot="title">
            <a-icon type="setting" />
            <span>其他设置</span>
          </span>
          <!-- <a-menu-item key="/poster"> 海报管理 </a-menu-item> -->
          <a-menu-item key="/paySet"> 支付设置 </a-menu-item>
          <a-menu-item key="/notice"> 首页公告 </a-menu-item>
          <a-menu-item key="/service"> 客服管理 </a-menu-item>
          <a-menu-item key="/banner"> 轮播图管理 </a-menu-item>
          <a-menu-item key="/trilogy"> 三部曲管理 </a-menu-item>
          <a-menu-item key="/version"> APP版本管理 </a-menu-item>
          <a-menu-item key="/appReview"> APP审核管理 </a-menu-item>
          <a-menu-item key="/jdPullNew"> 京东拉新设置 </a-menu-item>
          <a-menu-item key="/switchUrl"> 京东转链 </a-menu-item>
        </a-sub-menu>
        
      </a-menu>
    </a-layout-sider>
  </div>
</template>
<script>
export default {
  data() {
    return {
      current: [],
      rootSubmenuKeys: [
        "/otherSet",
        "/order",
        "/assignment",
        "/credentials"
      ],
      openKeys: [],
      slider_app: false,
      menu_app: false,
      collapsed: false,
    };
  },
  mounted() {
    // this.siderList = JSON.parse(window.localStorage.getItem("auths"));

    var router = this.$route.path.split("/");
    if (router.length==3) {
      this.current = ["/" + router[2]];
    this.openKeys = ["/" + router[1]];

    } else {
      this.current = ["/" + router[1]];
    }
    // var that = this;
    this.windowResize();
    window.addEventListener("resize", () => this.windowResize(), false);
  },
  methods: {
    windowResize() {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      this.clientH =
        document.documentElement.clientHeight || document.body.clientHeight;
      this.clientW =
        document.documentElement.clientWidth || document.body.clientWidth;
      this.statusChange();
    },
    statusChange() {
      if (this.clientW > 990) {
        this.collapsed = false;
        this.slider_app = false;
        this.menu_app = false;
      } else {
        this.collapsed = true;
        this.slider_app = true;
        this.menu_app = true;
      }
    },
    getMenu() {
      this.menu_app = !this.menu_app;
    },
    handleClick(e) {
      console.log(e);
      this.current = [e.key];
      if (e.keyPath.length>1) {
        this.$router.push(e.keyPath[1]+e.keyPath[0]);
      } else {
          this.$router.push(e.key);
      }
    },
    titleClick() {
      // console.log(2, "titleClick", e);
    },
    //  toggleCollapsed() {
    //   this.collapsed = !this.collapsed;
    // },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      console.log(openKeys)
      // console.log(latestOpenKey)
      // console.log(this.rootSubmenuKeys)
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
  },
};
</script>
<style scoped>
.ant-layout-sider {
  height: 100%;
  overflow-y: scroll;
}
.ant-layout-sider::-webkit-scrollbar{
  width:0
}
.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 7px;
}
.logo img {
  height: 50px;
}
.slider_app {
  position: absolute;
  left: 0;
  z-index: 100;
}
.slider_app_h {
  height: auto;
}
.menu_app {
  display: none;
}
.menu_app_bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
</style>