<template>
  <a-layout-header
    :style="{ background: '#fff', position: 'fixed', zIndex: 1, width: '100%' }"
  >
    <div class="logo"><img src="../assets/logo.png"/></div>
    <a-page-header>
      <template slot="extra">
         <a-dropdown-button>
      
        <a-icon slot="icon" type="user" />{{ nickName }}
      <a-menu slot="overlay" >
        <a-menu-item key="1"  @click="logout" style="background:#1890ff;color:#fff"> <a-icon type="rollback" />退出 </a-menu-item>
      </a-menu>
    </a-dropdown-button>
      </template>
    </a-page-header>
    
  </a-layout-header>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      nickName:window.localStorage.getItem("nickname")
    };
  },
   props:{
      title:{
          type:String,
          default:""
      }
  },
  methods: {
    logout() {
      var that = this;
      this.$confirm({
        title: "提示",
        content: "是否确定退出登录？",
        okText: "确定",
        okType: "warning",
        cancelText: "取消",
        onOk() {
          that.$logOut()
        },
        onCancel() {
        },
        class: "test",
      });
    },
  },
};
</script>
<style >
.logo{
  font-size: 24px;
    font-weight: bold;
    color: #000;
}
.ant-layout-header{
  padding:0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: unset!important;
}
@media screen and (max-width: 990px) {
  .ant-page-header-heading-extra {
    display: flex !important;
    justify-content: flex-end !important;
    padding-top:0!important;
    width: auto;
  }
  .logo span{
    display: none
  }
}
</style>